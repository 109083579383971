import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "info";
const modelPath = "/anggota/";

class Dashboard extends ModelBase {
  constructor() {
    let model = {
      info: {stase: {departemen: ""}, jadwal_stase_vlist: []}
    };
    super(modelName, model, [], [], modelPath);
  }
}

export default Dashboard;