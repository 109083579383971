<template>
  <section class="info-tiles">
    <div v-if="isPSPD && info.stase" class="container">
      <div class="card">
        <header class="card-header has-background-primary">
          <span class="card-header-title is-uppercase has-text-light">{{ info.stase.departemen }}</span>
          <small
            class="stase-date is-pulled-right has-text-light"
          >{{ info.stase.mulai }} - {{ info.stase.hingga }}</small>
        </header>
        <div class="columns no-margin is-mobile has-text-centered box-notif-pspd">
          <div class="column no-margin is-half">
            <div class="tile is-ancestor">
              <card-info
                :info="info.jml_jadwal"
                subtitle="Jadwal"
                targetName="jadwal-departemen-list"
                appName="stase"
                :sub_departemen="info.stase.departemen_id"
              ></card-info>
              <card-info
                :info="info.jml_keterampilan"
                subtitle="Keterampilan Klinis"
                targetName="aktivitas-keterampilan-list"
                appName="aktivitas"
                :sub_departemen="info.stase.departemen_id"
              ></card-info>
            </div>
          </div>

          <div class="column no-margin is-half">
            <div class="tile is-ancestor">
              <card-info
                :info="info.jml_ilmiah_belum"
                subtitle="Kegiatan Ilmiah"
                targetName="karyailmiah-list"
                appName="karyailmiah"
                :sub_departemen="info.stase.departemen_id"
              ></card-info>
              <card-info
                :info="info.jml_ujian_belum"
                subtitle="Ujian"
                targetName="ujian-list"
                appName="ujian"
                :sub_departemen="info.stase.departemen_id"
              ></card-info>
            </div>
          </div>
        </div>
        <div class="columns no-margin is-mobile has-text-centered box-notif-pspd">
          <div class="column no-margin is-half">
            <div class="tile is-ancestor">
              <card-info
                :info="info.jml_kegiatan_belum"
                subtitle="Pengelolaan Kasus"
                targetName="klinis-list"
                appName="aktivitas"
                :sub_departemen="info.stase.departemen_id"
              ></card-info>
              <a class="empty-card tile is-parent"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="!isPSPD" class="container">
      <div class="columns no-margin is-mobile has-text-centered">
        <div class="column no-margin is-half">
          <div class="tile is-ancestor">
            <card-info
              v-if="isAdmin"
              :info="info.jml_target_ket"
              subtitle="Target Keterampilan"
              targetName="target-ket"
              appName="programstudi"
            ></card-info>

            <card-info
              v-if="isAdmin"
              :info="info.jml_target_diag"
              subtitle="Target Diagnosis"
              targetName="target-diag"
              appName="programstudi"
            ></card-info>

            <card-info
              v-if="isPreceptor"
              :info="info.jml_perawatan_belum"
              subtitle="Pengelolaan Kasus"
              targetName="klinis-list"
              appName="perawatan"
            ></card-info>

            <card-info
            v-if="isPreceptor"
              :info="info.jml_aktivitas_belum"
              :subtitle="subTitleKeterampilan"
              :targetName="keterampilanListUrl"
              appName="aktivitas"
            ></card-info>
          </div>
        </div>

        <div class="column no-margin is-half">
          <div class="tile is-ancestor">
            <card-info
              :info="info.jml_ilmiah_belum"
              subtitle="Kegiatan Ilmiah"
              targetName="karyailmiah-list"
              appName="karyailmiah"
            ></card-info>
            <card-info
              :info="info.jml_ujian_belum"
              subtitle="Ujian"
              targetName="ujian-list"
              appName="ujian"
            ></card-info>
          </div>
        </div>
      </div>
      <div v-if="isAdmin" class="columns no-margin is-mobile has-text-centered">
        <div class="column no-margin is-half">
          <div class="tile is-ancestor">
            <card-info
              :info="info.jml_aktivitas_belum"
              :subtitle="subTitleKeterampilan"
              :targetName="keterampilanListUrl"
              appName="aktivitas"
            ></card-info>
            <card-info
              v-if="isAdminBagian"
              :info="info.jml_keterampilan"
              subtitle="Keterampilan Klinis Jejaring"
              targetName="aktivitas-jejaring"
              appName="aktivitas"
            ></card-info>
            <a class="empty-card tile is-parent"></a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading" class="card-content events-card">
      <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
    </div>
    <template v-if="isDashboardDataReady && !loading">
      <div v-if="isPreceptor" class="container">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light">AKTIVITAS PRECEPTOR</p>
        </header>
        <div class="columns no-margin">
          <div class="column no-padding is-full">
            <div class="card table-card-content" v-if="info.pencapaian_stase_list">
              <b-table
                bordered
                striped
                hoverable
                :loading="loading"
                :data="info.pencapaian_stase_list"
                :mobile-cards="false"
              >
                <b-table-column
                  field="stase"
                  label="Stase"
                  v-slot="props"
                >{{ props.row.mulai }} s.d {{ props.row.hingga }}</b-table-column>
                <b-table-column field="terverifikasi" label="Terverifikasi" v-slot="props">
                  <router-link
                    :to="{ name: 'klinis-list', params: { stase: props.row.id } }"
                  >Pengelolaan Kasus : {{ props.row.perawatan_ver }}</router-link>
                  <hr />
                  <router-link
                    :to="{ name: 'aktivitas-keterampilan-list', params: { stase: props.row.id } }"
                  >Keterampilan Klinis : {{ props.row.ket_ver }}</router-link>
                  <hr />
                  <router-link
                    :to="{ name: 'karyailmiah-list', params: { stase: props.row.id } }"
                  >Kegiatan Ilmiah : {{ props.row.ilmiah_ver }}</router-link>
                  <hr />
                  <router-link
                    :to="{ name: 'ujian-list', params: { stase: props.row.id } }"
                  >Ujian : {{ props.row.ujian_ver }}</router-link>
                </b-table-column>

                <template slot="empty">
                  <section class="section">
                    <div class="content has-text-grey has-text-centered">
                      <p>Belum ada pencapaian stase.</p>
                    </div>
                  </section>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isPSPD" class="container">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light">STASE</p>
          <router-link :class="'card-header-icon'" :to="{ name: 'stase-list' }">
            <span class="icon">
              <b-icon class="has-text-light" size="is-small" pack="fas" icon="chevron-right" />
            </span>
          </router-link>
        </header>
        <div class="columns no-margin">
          <div class="column no-padding is-full">
            <div
              class="card has-text-grey has-text-centered"
              v-if="info.jadwal_stase_vlist && info.jadwal_stase_vlist.length == 0"
            >
              <p>Belum ada jadwal stase yang akan datang.</p>
            </div>
            <div
              v-else
              v-for="jadwal_stase in info.jadwal_stase_vlist"
              class="card"
              :key="jadwal_stase.id"
            >
              <span class="card-header-title">
                <strong>{{ jadwal_stase.departemen }}</strong>
              </span>
              <small class="stase-date">{{ jadwal_stase.mulai }} s.d {{ jadwal_stase.hingga }}</small>
            </div>
          </div>
        </div>
      </div>
    </template>
  </section>
</template>


<script>
import { mapState, mapGetters } from "vuex";
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import fetchActivatedMixin from "@/apps/core/mixins/fetchActivatedMixin";
import Dashboard from "../models/dashboard.js";

export default {
  name: "Dashboard",
  components: {
    CardInfo: () => import("../components/CardInfo"),
  },
  data() {
    this.dashboardMdl = new Dashboard();
    return this.dashboardMdl.getObservables();
  },
  computed: {
    ...mapState("accounts", ["userId"]),
    ...mapGetters("accounts", [
      "isAdmin",
      "isAdminBagian",
      "isPreceptor",
      "isPSPD",
    ]),
    isDashboardDataReady() {
      return this.info != null;
    },
    keterampilanListUrl() {
      return this.isAdminBagian ? "aktivitas-jejaring" : "aktivitas-keterampilan-list";
    },
    subTitleKeterampilan() {
      return this.isAdminBagian ? "Keterampilan Klinis Jejaring" : "Keterampilan Klinis";
    },
  },
  methods: {
    fetchData() {
      this.dashboardMdl.load("dashboard");
    },
  },
  mixins: [onlineCheckMixin, fetchActivatedMixin],
};
</script>


<style scoped lang="scss">
div.container {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  padding-bottom: 0.5rem !important;
}

.empty-card {
  padding: 0rem;
}

::v-deep .box-notif-pspd article.box {
  background-color: #f1f1f1;
}

::v-deep .box-notif-pspd {
  padding: 0px 10px 0px 10px;
}

::v-deep .box-notif-pspd:first-of-type {
  padding-top: 10px;
}

::v-deep .box-notif-pspd:last-of-type {
  padding-bottom: 10px;
}

.stase-date {
  padding: 0.75rem;
}

.is-black {
  color: #000000 !important;
}

.card-header-title {
  font-weight: 500;
}

hr {
  border: none;
  border-top: 1px solid;
  color: #dbdbdb;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>
